/* font-stretch: ultra-condensed|extra-condensed|condensed|semi-condensed|normal|semi-expanded|expanded|extra-expanded|ultra-expanded|initial|inherit; */

.nav-item, .dropdown-item {
    font-size: 1.4rem !important;
    font-weight: 480;
    font-family: Arial Narrow,Arial,sans-serif !important; 
}
.nav-item a {
    color: #000011 !important;
}

h1, h2 {
    color: #002060 !important;
    font-family: Arial Narrow,Arial,sans-serif !important; 
}
