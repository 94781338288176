/* Sticky footer styles
-------------------------------------------------- */

.brand {
    background-image: url($brand-image);
}

.printer {
    background-image: url($printer-image);
}

.die {
    background-image: url($die-image);
}

.die-bw {
    background-image: url($die-bw-image);
}

.random-walk-on-sphere {
    background-image: url($bmonsphere-image);
}

.random-walk-on-sphere-big {
    background-image: url($bmonsphere-big-image);
}


.conditional-expectation {
    background-image: url($conditional-expectation-image);
}

.cyberbrain {
    background-image: url($cyberbrain-image);
}

.statscube {
    background-image: url($statscube-image);
}

.appliedmathbg {
    background-image: url($appliedmathbg-image);
}

.appliedmathy {
    background-image: url($appliedmathy-image);
}


.mrychlik {
    background-image: url($mrychlik-image);
}


.favicon {
    background-image: url($favicon-image);
}

.inspire {
    min-height: 100%;
    background-image: url($inspire-image);
    background-repeat: no-repeat;
    background-size: cover !important;
}

.people-detector {
    background-image: url($people-detector-image);    
}


.gradient {
    background-image: linear-gradient(to top, rgba(168,237,234,1) 0%, rgba(254,214,227,1) 100%);
    background-repeat: no-repeat;
    background-size: cover !important;
    background-attachment: fixed;
}

.bg-custom {
    background-color: #e0e0f0;
}

.bg-boring {
    background-color: #e0e0e0;
}

.login-status-message {
    font-weight: bold;
    position: relative;
    float: right;
    padding: 5px;
}

html, body {
    height: 100%;
    /* The html and body elements cannot have any padding or margin. */
}


/* Wrapper for page content to push down footer */
#wrap {
    min-height: 100%;
    height: auto !important;
    /* height: 100%; */
    width: 100%;
    margin: -75px auto ( -$footer-height );
    /* Pad bottom by footer height */
    padding: 75px 10px $footer-height;
}


@include media-breakpoint-down(sm) {
    #wrap {
	min-width: 100%;
	width: auto;
    }

    html, body {
	background-image: none;
    }

}

@include media-breakpoint-up(sm) {
    #wrap {
	width: 70%;
	/* background-image: linear-gradient(to top, rgba(168,237,234,0.8) 0%, rgba(254,214,227,0.9) 100%); */
	/* background-size: cover !important; */
    }
    .company-name {
	font-size: 4rem;
    }

}

/* Set the fixed height of the footer here */
#footer {
    height: $footer-height;
}

#user_login {
    float: right;
}

/* Lastly, apply responsive CSS fixes as necessary */

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

#footer {
    width: 100%;
    margin-top:2rem;		// Because of navbar
}

#copyright {
    text-align: center;
}

#logo {
    height: 50px;
    // Subtract padding of the navbar
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
}

#login {
    background-color: rgba(197,201,184,.7);
    color: rgba(51,51,51,.7);
    padding:20px;
}

table tr.high {
    background-color:pink;
}

table tr.low {
    background-color:lightgray;
}
