@import './_myvariables.scss';
@import './navbar.scss';

// set if you're also including font-awesome
//$fa-font-path: "~font-awesome/fonts";
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

// customize some Bootstrap variables
$brand-primary: darken(#428bca, 20%);

//** Background color for `<body>`.
$body-bg:               #fff !default;
//** Global text color on `<body>`.

$footer-height:                    48px;

//** This declares images for various purposes
$background-image:               "../images/background.png";
$brand-image:                    "../images/brand.png";
$printer-image:                  "../images/printer.png";
$die-image:                      "../images/die.png";
$die-bw-image:                   "../images/Nuvola_apps_atlantik.png";
$mrychlik-image:                 "../images/mrychlik.jpg";
$favicon-image:                  "../images/favicon.ico";
$inspire-image:                  "../images/inspire.png";
$bmonsphere-image:               "../images/BMonSphere.jpg";
$bmonsphere-big-image:           "../images/BMonSphereBig.jpg";
$people-detector-image:          "../images/PeopleDetector.png";
$conditional-expectation-image:  "../images/cond_expectation.png";
$cyberbrain-image:               "../images/CyberBrain.png";
$statscube-image:                "../images/StatsCube.png";
$appliedmathbg-image:            "../images/AppliedMathBg.png";
$appliedmathy-image:             "../images/AppliedMathy.png";
$statsbg-image:                  "../images/StatsBg.png";


// the ~ allows you to reference things in node_modules
//@import '~bootstrap-sass/assets/stylesheets/bootstrap';

@import '~bootstrap/scss/bootstrap';
@import '../css/sticky-footer.scss';
